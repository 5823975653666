import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import photo from './pictures/photo_2022-06-22_12-46-28.jpg';
import photo2 from './pictures/photo_2022-06-22_12-46-27.jpg';
import photo3 from './pictures/photo_2022-06-22_12-46-26.jpg';
import photo4 from './pictures/photo_2022-06-27_14-38-21.jpg';

import './thanks.css';
import { useDispatch, useSelector } from "react-redux";
import { closeThanks } from '../../redux/actions/thanks';
import { openWords } from '../../redux/actions/words';

const Thanks = () => {

  const dispatch = useDispatch();
  const thanks = useSelector(store => store.thanks);

  const closeHandler = () => {
    dispatch(closeThanks());
    dispatch(openWords());
  }

  return (
    <>
   
    <div className={ thanks ? "modal10 active" : "modal10"} onClick={closeHandler}>
      <div className={ thanks ? "modal__content10 active" : "modal__content10"} onClick={e => e.stopPropagation()}>
      <Swiper
            cssMode={true}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            mousewheel={true}
            keyboard={true}
            modules={[Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper"
          >
           <SwiperSlide><img className='photo' src={photo} alt='отзыв'/></SwiperSlide>
           <SwiperSlide><img className='photo' src={photo2} alt='отзыв'/></SwiperSlide>
           <SwiperSlide><img className='photo' src={photo3} alt='отзыв'/></SwiperSlide>
           <SwiperSlide><img className='photo' src={photo4} alt='отзыв'/></SwiperSlide>
          </Swiper>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeHandler}/>
    </div>
    
   </>
  )
}

export default Thanks
