import React from 'react';

import './mainPage.css';
import { useSelector } from 'react-redux';

export default function MainPage() {

  const words = useSelector(store => store.words)
  
  
  return (
    <>
    
      <div className='continer'>
      <div id={ words ? "container" : "container_false"}>
          <div id="flip"> 
            <div><div>Создание</div></div>
            <div><div>Реставрация</div></div>
            <div><div>Ремонт</div></div>
          </div>
         мебели
      </div>
      </div>
 
  
      
    </>

  )
}
