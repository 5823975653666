import React from 'react';
import './bio.css';

import { useDispatch, useSelector } from "react-redux";
import { closeBio } from '../../redux/actions/bio';

const Bio = () => {

  const dispatch = useDispatch();
  const bio = useSelector(store => store.bio);

  const closeHandler = () => {
    dispatch(closeBio())
  }
// +7 989 819-10-94.
  return (
    <>
   
    <div className={ bio ? "modal11 active" : "modal11"} onClick={closeHandler}>
      <div className={ bio ? "modal__content11 active" : "modal__content11"} onClick={e => e.stopPropagation()}>
           
           <span className='text4'>Меня зовут Евгений, около 20 лет занимаюсь комплексным ремонтом и восстановлением любой мебели.
           <br/>
           <br/> Выполняю любые заказы. 
           <br/> Мой телефон:  <a href="tel:+7 989 819-10-94">+7 989 819-10-94</a></span>

      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeHandler}/>
    </div>
    
   </>
  )
}

export default Bio;
