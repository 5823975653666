import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  setClose, 
  setOpenModal1, setCloseModal1, 
  setOpenModal2, setCloseModal2,
  setOpenModal3, setCloseModal3,
  setOpenModal4, setCloseModal4,
  setOpenModal5, setCloseModal5,
  setOpenModal6, setCloseModal6,} from '../../redux/actions/modal';

import './modal.css';

// для крутилки слов 
import { openWords } from '../../redux/actions/words';

import ModalFor1 from './ModalFor1/ModalFor1';
import ModalFor2 from './ModalFor2/ModalFor2';
import ModalFor3 from './ModalFor3/ModalFor3';
import ModalFor4 from './ModalFor4/ModalFor4';
import ModalFor5 from './ModalFor5/ModalFor5';
import ModalFor6 from './ModalFor6/ModalFor6';


const Modal = () => {

  const dispatch = useDispatch();
  const mainModal = useSelector((store) => store.mainModal);

  // закрытие 
  const closeModal = () => {
    dispatch(setClose());
    dispatch(setCloseModal1());
    dispatch(setCloseModal2());
    dispatch(setCloseModal3());
    dispatch(setCloseModal4());
    dispatch(setCloseModal5());
    dispatch(setCloseModal6());
    dispatch(openWords());
  }
  //------------------------------
  // модальное окно для кресел
  const openModalFor1 = () => {
    //открыть
    dispatch(setOpenModal1());
    //закрыть
    dispatch(setCloseModal2());
    dispatch(setCloseModal3());
    dispatch(setCloseModal4());
    dispatch(setCloseModal5());
    dispatch(setCloseModal6());
  }
  //------------------------------
  // модальное окно для стульев
  const openModalFor2 = () => {
    //открыть
    dispatch(setOpenModal2());
    //закрыть
    dispatch(setCloseModal1());
    dispatch(setCloseModal3());
    dispatch(setCloseModal4());
    dispatch(setCloseModal5());
    dispatch(setCloseModal6());
  }
   //------------------------------
   // модальное окно для стульев
   const openModalFor3 = () => {
    //открыть
    dispatch(setOpenModal3());
    //закрыть
    dispatch(setCloseModal1());
    dispatch(setCloseModal2());
    dispatch(setCloseModal4());
    dispatch(setCloseModal5());
    dispatch(setCloseModal6());
  }
  //------------------------------
  // модальное окно для кроватей
  const openModalFor4 = () => {
    //открыть
    dispatch(setOpenModal4());
    //закрыть
    dispatch(setCloseModal1());
    dispatch(setCloseModal2());
    dispatch(setCloseModal3());
    dispatch(setCloseModal5());
    dispatch(setCloseModal6());
  } 
  //------------------------------
  // модальное окно для шкафа-купе
  const openModalFor5 = () => {
    //открыть
    dispatch(setOpenModal5());
    //закрыть
    dispatch(setCloseModal1());
    dispatch(setCloseModal2());
    dispatch(setCloseModal3());
    dispatch(setCloseModal4());
    dispatch(setCloseModal6());
  }
  //------------------------------
  // модальное окно для кухонной мебели
  const openModalFor6 = () => {
    //открыть
    dispatch(setOpenModal6());
    //закрыть
    dispatch(setCloseModal1());
    dispatch(setCloseModal2());
    dispatch(setCloseModal3());
    dispatch(setCloseModal4());
    dispatch(setCloseModal5());
  }

  return (
    <>
    <div className={mainModal ? 'mainStyleModal' : "modal"} >
    <div className={mainModal ? "modal active" : "modal"} onClick={closeModal}>
      <div className={mainModal ? "modal__content active" : "modal__content"} onClick={e => e.stopPropagation()}>
     
        <div className='list'>
      <h5 className='title'>Ремонт</h5>
      <p onClick={openModalFor1}>Кресел</p>
      <p onClick={openModalFor2}>Стульев</p>
      <p onClick={openModalFor3}>Диванов</p>
      <p onClick={openModalFor4}>Кроватей</p>
      <p onClick={openModalFor5}>Шкафа купе</p>
      <p onClick={openModalFor6}>Кухонной мебели</p>
        </div>

      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeModal} />
    </div>
    </div>

    <>
    <ModalFor1/>
    <ModalFor2/>
    <ModalFor3/>
    <ModalFor4/>
    <ModalFor5/>
    <ModalFor6/>
    </>
     
    </>
  )
}

export default Modal;
