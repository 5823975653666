import { initState } from "../initState";

export const galleryReducer = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case 'SWITCH_ON_GALLERY': 
    return true;
    case 'SWITCH_OFF_GALLERY':
      return false;

      default:
        return state;
  }
}
