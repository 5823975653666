import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { setOpen, setClose } from '../../redux/actions/modal';
import { openGallery, closeGallery } from '../../redux/actions/gallery';
import { openRequest, closeRequest } from '../../redux/actions/request';
import { openGifts, closeGifts } from '../../redux/actions/gifts';
import { openThanks, closeThanks } from '../../redux/actions/thanks';
import { openBio, closeBio } from '../../redux/actions/bio';

// для закрытия модалок 1 окна (подмодалок)
import { setCloseModal1, setCloseModal2, 
         setCloseModal3, setCloseModal4,
         setCloseModal5, setCloseModal6 } from '../../redux/actions/modal';

// для закрытия крутилок слов
import { closeWords, openWords } from '../../redux/actions/words';

import './navbar.css' ;
import Modal from '../ModalMain/Modal';
import Gallery from '../Gallery/Gallery';
import Request from '../Request/Request';
import Gifts from '../Gifts/Gifts';
import Thanks from '../Thanks/Thanks';
import Bio from '../Bio/Bio';

const Navbar = () => {

  const dispatch = useDispatch();
  
// для закрытия модалок 1 окна (подмодалок)
  function closeAllModal () {
    dispatch(setCloseModal1());
    dispatch(setCloseModal2());
    dispatch(setCloseModal3());
    dispatch(setCloseModal4());
    dispatch(setCloseModal5());
    dispatch(setCloseModal6());
  }

  const openModal = (e) => {
    e.preventDefault()
    dispatch(setOpen());
    dispatch(closeGallery());
    dispatch(closeRequest());
    dispatch(closeGifts());
    dispatch(closeThanks());
    dispatch(closeBio());
    dispatch(closeWords());
  }

  const galleryHandler = (e) => {
   e.preventDefault();
   dispatch(openGallery());
   dispatch(setClose());
      closeAllModal();
   dispatch(closeRequest());
   dispatch(closeGifts());
   dispatch(closeThanks());
   dispatch(closeBio());
   dispatch(closeWords());
  }

  const requestHandler = (e) => {
    e.preventDefault();
    dispatch(openRequest());
    dispatch(closeGallery());
    dispatch(setClose());
      closeAllModal();
    dispatch(closeGifts());
    dispatch(closeThanks());
    dispatch(closeBio());
    dispatch(closeWords());
  }
  
  const giftsHandler = (e) => {
    e.preventDefault();
    dispatch(openGifts());
    dispatch(setClose());
      closeAllModal();
    dispatch(closeGallery());
    dispatch(closeRequest());
    dispatch(closeThanks());
    dispatch(closeBio());
    dispatch(closeWords());
    dispatch(openWords())
  }

  const thanksHandler = (e) => {
    e.preventDefault();
    dispatch(openThanks());
    dispatch(setClose());
      closeAllModal();
    dispatch(closeGallery());
    dispatch(closeRequest());
    dispatch(closeGifts());
    dispatch(closeBio());
    dispatch(closeWords());
  }

  const bioHandler = (e) => {
    e.preventDefault();
    dispatch(openBio());
    dispatch(setClose());
      closeAllModal();
    dispatch(closeGallery());
    dispatch(closeRequest());
    dispatch(closeGifts());
    dispatch(closeThanks());
    dispatch(openWords())
  }

  return (
    <>
    <nav>
     <div className='menu-top'>
      <Link to='#' onClick={openModal}> 
        <img src="/svg/tools.svg" 
        alt="ремонт" 
        title="Ремонт мебели"
        style={{width: "30px", height: "30px", marginTop: "20px"}}/>
      </Link>

      <Link to='#' onClick={galleryHandler}> 
        <img src="/svg/images.svg" 
        alt="Галерея" 
        title="Галерея выполненных работ"
        style={{width: "30px", height: "30px"}}/>
      </Link>

      <Link to='#' onClick={requestHandler}> 
        <img src="/svg/telephone-fill.svg" 
        alt="телефон" 
        title="Сделать заявку"
        style={{width: "30px", height: "30px"}}/>
      </Link>
      </div>

         <div className='menu-bottom'>
          <Link to='#' onClick={giftsHandler}> 
            <img src="/svg/gift.svg" 
            alt="Новости"
            title="Акции и подарки"
            style={{width: "30px", height: "30px"}}/>
          </Link>

          <Link to='#' onClick={thanksHandler}> 
            <img src="/svg/emoji-kiss.svg" 
            alt="Отзывы" 
            title="Отзывы других клиентов"
            style={{width: "30px", height: "30px"}}/>
          </Link>

          <Link to='#' onClick={bioHandler}> 
            <img src="/svg/info-circle.svg" 
            alt="Информация" 
            title="Информация"
            style={{width: "30px", height: "30px"}}/>
          </Link>
         </div>
      </nav>
      
      <>
      <Modal/>
      <Gallery/>
      <Request/>
      <Gifts/>
      <Thanks/>
      <Bio/>
      </>
  
    </>
  )
}

export default Navbar;
