// открытие модального окна для вкладки ремонт
export const setOpen = () => {
  return {type: 'SWITCH_ON'}
}

// закрытие модального окна для вкладки ремонт
export const setClose = () => {
  return {type: 'SWITCH_OFF'}
}
// -----------------------------------------------------
// открытие модального окна для вкладки кресел 
export const setOpenModal1 = () => {
  return {type: 'SWITCH_ON_Modal1'}
}

// закрытие модального окна для вкладки кресел 
export const setCloseModal1 = () => {
  return {type: 'SWITCH_OFF_Modal1'}
}
// -----------------------------------------------------
// открытие модального окна для вкладки стулья
export const setOpenModal2 = () => {
  return {type: 'SWITCH_ON_Modal2'}
}

// закрытие модального окна для вкладки стулья 
export const setCloseModal2 = () => {
  return {type: 'SWITCH_OFF_Modal2'}
}
// -----------------------------------------------------
// открытие модального окна для вкладки диваны
export const setOpenModal3 = () => {
  return {type: 'SWITCH_ON_Modal3'}
}

// закрытие модального окна для вкладки диваны 
export const setCloseModal3 = () => {
  return {type: 'SWITCH_OFF_Modal3'}
}
// -----------------------------------------------------
// открытие модального окна для вкладки кровати
export const setOpenModal4 = () => {
  return {type: 'SWITCH_ON_Modal4'}
}

// закрытие модального окна для вкладки кровати
export const setCloseModal4 = () => {
  return {type: 'SWITCH_OFF_Modal4'}
}
// -----------------------------------------------------
// открытие модального окна для вкладки шкафа-купе
export const setOpenModal5 = () => {
  return {type: 'SWITCH_ON_Modal5'}
}

// закрытие модального окна для вкладки шкафа-купе
export const setCloseModal5 = () => {
  return {type: 'SWITCH_OFF_Modal5'}
}
// -----------------------------------------------------
// открытие модального окна для вкладки кухонной мебели
export const setOpenModal6 = () => {
  return {type: 'SWITCH_ON_Modal6'}
}

// закрытие модального окна для вкладки кухонной мебели
export const setCloseModal6 = () => {
  return {type: 'SWITCH_OFF_Modal6'}
}
