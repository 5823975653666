import { initState } from "../initState";

export const giftsReducer = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case 'SWITCH_ON_GIFTS': 
    return true;
    case 'SWITCH_OFF_GIFTS':
      return false;

      default:
        return state;
  }
}
