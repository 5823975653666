
export const openRequest= () => {
  return {type: 'SWITCH_ON_REQUEST'}
}

export const closeRequest = () => {
  return {type: 'SWITCH_OFF_REQUEST'}
}
// не работает ?
// export const userTypingRequest = (myEvent) => {
//   return { type:' USER_TYPING', payload: { [myEvent.target.name]: myEvent.target.value } }
// } 

export const userClearRequest = () => {
  return { type: 'CLEAR_INPUTS', payload: {}}
}
export const submitRequest = (e) => async (dispatch) => {
  // const TOKEN = '';
  // const CHAT_ID = ''
  
}
