import React from "react";
import "./request.css";
import { useDispatch, useSelector } from "react-redux";
import { closeRequest, userClearRequest } from "../../redux/actions/request";
import { openWords } from "../../redux/actions/words";
import axios from "axios";
// не работает ? 
// import { userTypingRequest } from "../../redux/actions/request";


const Request = () => {

  const dispatch = useDispatch();
  const booleanRequest = useSelector(store => store.request);
  const inp = useSelector(store => store.requestInputs);
  const token = process.env.REACT_APP_TOKEN;
  const CHAT_ID = process.env.REACT_APP_CHAT_ID;

  // закрытие
  const closeHandler = () => {
    dispatch(closeRequest());
    dispatch(openWords());
    inp.name = null;
    inp.number = null;
  };
  // отслеживать изменения инпутов при вводе данных (не работает)
  // const changeFormHandler = (e) => {
  //   dispatch(userTypingRequest(e));
  // };

  
  const submitHandler = (e) => {
    e.preventDefault();
    
    const URI_API = `https://api.telegram.org/bot${ token }/sendMessage`
    
    let message = `<b>Заявка по мебели</b>,\n`;
    message += `<b>Имя: </b> ${inp.name}\n`;
    message += `<b>Номер телефона: </b> ${inp.number}\n`;

    axios.post(URI_API, {
      chat_id: CHAT_ID,
      parse_mode: 'html',
      text: message
    })
    .then(() => {
      dispatch(userClearRequest());
    })
    .catch((err) => {
      console.warn(err)
    })
    .finally(() => {
      dispatch(closeRequest());
    })
    // console.log(message)
  };

  return (
    <>
      
        <div
          className={booleanRequest ? "modal8 active" : "modal8"}
          onClick={closeHandler}
        >
          <div
            className={
              booleanRequest ? "modal__content8 active" : "modal__content8"
            }
            onClick={(e) => e.stopPropagation()}
          >
            <h5>Заявка на ремонт</h5>

            <form className="col s12" onSubmit={submitHandler}>
              <div className="column">
                <div className="input-field col s6">
                  <input
                    id="icon_prefix"
                    type="text"
                    name="name"
                    value={inp.name || ''}
                    onChange={(e) => dispatch({type: 'USER_TYPING', payload: {[e.target.name]: e.target.value}})}
                    className="validate"
                  />
                  <label className="labelName" htmlFor="icon_prefix">
                    Ваше имя
                  </label>
                </div>

                <div className="input-field col s6">
                  <input
                    id="icon_telephone"
                    type="text"
                    name="number"
                    value={inp.number || ''}
                    onChange={(e) => dispatch({type: 'USER_TYPING', payload: {[e.target.name]: e.target.value}})}
                    className="validate"
                  />
                  <label className="labelName" htmlFor="icon_telephone">
                    Телефон
                  </label>
                </div>

                {/* <div className="input-field col s6">
                  <input
                    id=""
                    type="text"
                    name="userText"
                    value={inp.userText || ''}
                    onChange={(e) => dispatch({type: 'USER_TYPING', payload: {[e.target.name]: e.target.value}})}
                    className="validate"
                  />
                  <label className="labelName" htmlFor="text">
                    Уточните, что нужно
                  </label>
                </div> */}

                <button disabled={ !inp.number || !inp.name } className="btn waves-effect waves-light" type="submit">
                  Отправить
                  <i className="material-icons right"></i>
                </button>
              </div>
            </form>
          </div>
          <img
            src="/svg/x-lg.svg"
            alt="закрыть окошко"
            title="Закрыть"
            style={{ width: "25px", height: "25px", padding: "1px" }} 
            onClick={closeHandler}
          />
        </div>
     
    </>
  );
};

export default Request;
