import React from 'react';
import './modalfor3.css';

import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal3 } from '../../../redux/actions/modal';

const ModalFor3 = () => {

  const dispatch = useDispatch();
  const modalFor3 = useSelector((store) => store.modalFor3);

  // закрытие 
  const closeModal3 = () => {
    dispatch(setCloseModal3())
  }

  return (
  <>
    <div className='modalfor3'>
    <div className={ modalFor3 ? "modal active3" : "modal3"} onClick={closeModal3}>
      <div className={ modalFor3 ? "modal__content active3" : "modal__content3"} onClick={e => e.stopPropagation()}>
           <ul className='list3'>
             <li>Декорирование</li>
             <li>Ремонт механизма</li>
             <li>Замена наполнителя</li>
             <li>Укрепление каркаса</li>
             <li>Замена обивки дивана</li>
           </ul>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeModal3}/>
    </div>
    </div>
    
   
   </>
  )
}

export default ModalFor3;
