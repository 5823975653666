import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import photo from './images/1623594518291.jpg'
import photo1 from './images/20201230_110626.jpg';
import photo2 from './images/IMG_20181221_211503.jpg';
import photo3 from './images/IMG_20190119_162940.jpg';
import photo4 from './images/IMG_20190119_162901.jpg';
import photo5 from './images/IMG_20181126_103301.jpg';
import photo6 from './images/IMG_20180126_153429.jpg';
import photo7 from './images/071120121374.jpg';
import photo8 from './images/20220604_205514.jpg';
import photo9 from './images/20220508_165213.jpg';
import photo10 from './images/photo_2022-06-16_11-06-21.jpg';
import photo11 from './images/20220322_123317.jpg';
import photo12 from './images/20220322_131735.jpg';
import photo13 from './images/20220322_133816.jpg';
import photo14 from './images/IMG_20191221_190736.jpg';
import photo15 from './images/IMG_20191118_212456 (2).jpg';
import photo16 from './images/IMG_20190614_001234.jpg';
import photo17 from './images/IMG_20190613_232603 (2).jpg';
import photo18 from './images/IMG_5391.JPG';
import photo19 from './images/IMG_20190828_175708.jpg';
import photo20 from './images/IMG_20190827_162108 (2).jpg';
import photo21 from './images/IMG_20190930_161641.jpg';
import photo22 from './images/IMG_20191102_002617.jpg';
import photo23 from './images/IMG_20180114_183748.jpg';
import photo24 from './images/IMG_20180114_183821.jpg';
import photo25 from './images/IMG_20191203_001354.jpg';

import './gallery.css';

import { useDispatch, useSelector } from 'react-redux';
import { closeGallery } from '../../redux/actions/gallery';
import { openWords } from '../../redux/actions/words';

const Gallery = () => {

  const dispatch = useDispatch();
  const gallery = useSelector((store) => store.gallery)

  const closeHandler = () => {
    dispatch(closeGallery());
    dispatch(openWords())
  }

  return ( 
    <>
   
      <div className={ gallery ? "modal7 active" : "modal7"} onClick={closeHandler}>
      <div className={ gallery ? "modal__content7 active" : "modal__content7"} onClick={e => e.stopPropagation()}>
          <Swiper
            cssMode={true}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 4000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            navigation={true}
            pagination={{
              clickable: true,
            }}
            mousewheel={true}
            keyboard={true}
            modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
            className="mySwiper"
          >
            <SwiperSlide><img className='photo' src={photo} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo1} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo2} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo3} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo4} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo5} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo6} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo7} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo8} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo9} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo10} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo11} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo12} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo13} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo14} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo15} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo16} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo17} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo18} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo19} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo20} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo21} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo22} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo23} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo24} alt='шкаф'/></SwiperSlide>
            <SwiperSlide><img className='photo' src={photo25} alt='шкаф'/></SwiperSlide>
          </Swiper>
     </div>
        <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeHandler}/>
     </div>
    
    </>
    
  )
}

export default Gallery;


