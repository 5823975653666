import { initState } from "../initState";

export const modalReducer1 = (state = initState, action) => {
  const { type } = action;
  switch (type) {
    case 'SWITCH_ON_Modal1': 
    return true;
    case 'SWITCH_OFF_Modal1':
      return false;

      default:
        return state;
  }
}
