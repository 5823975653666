export const initState = {

  mainModal: false,
  gallery: false,
  request: false,
  requestInputs: {},
  gifts: false,
  thanks: false,
  bio: false, 
 
  modalFor1: false,
  modalFor2: false,
  modalFor3: false,
  modalFor4: false,
  modalFor5: false,
  modalFor6: false,

  // крутилка слов
  words: true
}
