import React from 'react';
import './gifts.css';
import { useDispatch, useSelector } from "react-redux";
import { closeGifts } from '../../redux/actions/gifts';


const Gifts = () => {

  const dispatch = useDispatch();
  const gifts = useSelector(store => store.gifts)

  const closeHandler = () => {
    dispatch(closeGifts())
  }

  return (
    <>
   
    <div className={ gifts ? "modal9 active" : "modal9"} onClick={closeHandler}>
      <div className={ gifts ? "modal__content9 active" : "modal__content9"} onClick={e => e.stopPropagation()}>
           <span className='text1'>
            Об акциях и подарках уточняйте у мастера!
           </span>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeHandler}/>
    </div>
    
   </>
  )
}

export default Gifts;
