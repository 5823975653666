import React from 'react';
import './modalfor2.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal2 } from '../../../redux/actions/modal';

const ModalFor2 = () => {

  const dispatch = useDispatch();
  const modalFor2 = useSelector((store) => store.modalFor2);

  // закрытие 
  const closeModal2 = () => {
    dispatch(setCloseModal2())
  }

  return (
  <>
    <div className='modalfor2'>
    <div className={ modalFor2 ? "modal active2" : "modal2"} onClick={closeModal2}>
      <div className={ modalFor2 ? "modal__content active2" : "modal__content2"} onClick={e => e.stopPropagation()}>
           <ul className='list2'>
             <li>Замена обивки стула</li>
             <li>Ремонт каркаса</li>
             <li>Восстановление лкп</li>
             <li>Замена наполнителя</li>
           </ul>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeModal2}/>
    </div>
    </div>
    
   
   </>
  )
}

export default ModalFor2;
