import React from 'react';
import './App.css';

import MainPage from './components/MainPage/MainPage';
import Navbar from './components/Navbar/Navbar'



function App () {

  
  return (

    <div className='app'>
      <Navbar/>
      <MainPage/>
     
    </div>
  )
}

export default App;
