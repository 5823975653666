import React from 'react';
import './modalfor4.css';

import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal4 } from '../../../redux/actions/modal';

const ModalFor4 = () => {

  const dispatch = useDispatch();
  const modalFor4 = useSelector((store) => store.modalFor4);

  // закрытие 
  const closeModal4 = () => {
    dispatch(setCloseModal4())
  }

  return (
  <>
    <div className='modalfor4'>
    <div className={ modalFor4 ? "modal active4" : "modal4"} onClick={closeModal4}>
      <div className={ modalFor4 ? "modal__content active4" : "modal__content4"} onClick={e => e.stopPropagation()}>
           <ul className='list4'>
             <li>Замена обивки</li>
             <li>Замена матраса</li>
             <li>Замена ламелей</li>
             <li>Ремонт изголовья</li>
             <li>Ремонт механизма</li>
             <li>Укрепление каркаса</li>
             <li>Устранение дефектов</li>
           </ul>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeModal4}/>
    </div>
    </div>
    
   
   </>
  )
}

export default ModalFor4;
