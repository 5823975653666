import React from 'react';
import './modalfor6.css';

import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal6 } from '../../../redux/actions/modal';

const ModalFor6 = () => {

  const dispatch = useDispatch();
  const modalFor6 = useSelector((store) => store.modalFor6);

  // закрытие 
  const closeModal6 = () => {
    dispatch(setCloseModal6())
  }

  return (
  <>
    <div className='modalfor6'>
    <div className={ modalFor6 ? "modal active6" : "modal6"} onClick={closeModal6}>
      <div className={ modalFor6 ? "modal__content active6" : "modal__content6"} onClick={e => e.stopPropagation()}>
           <ul className='list6'>
             <li>Ремонт лкп</li>
             <li>Замена петель</li>
             <li>Ремонт ящиков</li>
             <li>Замена панелей</li>
             <li>Ремонт фасадов</li>
             <li>Замена фурнитуры</li>
             <li>Замена столешницы</li>
             <li>Устранение скрипов</li>
             <li>Перетяжка/смена обивки</li>
             <li>Восстановление покрытия</li>
             <li>Восстановление геометрии</li>
           </ul>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeModal6}/>
    </div>
    </div>
    
   
   </>
  )
}

export default ModalFor6;
