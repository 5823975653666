import React from 'react';
import './modalfor1.css';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal1 } from '../../../redux/actions/modal';

const ModalFor1 = () => {

  const dispatch = useDispatch();
  const modalFor1 = useSelector((store) => store.modalFor1);

  // закрытие 
  const closeModal1 = () => {
    dispatch(setCloseModal1())
  }

  return (
   <>
    <div className='modalfor1'>
    <div className={ modalFor1 ? "modal active1" : "modal1"} onClick={closeModal1}>
      <div className={ modalFor1 ? "modal__content active1" : "modal__content1"} onClick={e => e.stopPropagation()}>
           <ul className='list1'>
             <li>Замена обивки кресла</li>
             <li>Ремонт каркаса</li>
             <li>Восстановление лкп</li>
             <li>Замена наполнителя</li>
           </ul>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeModal1} />
    </div>
    </div>
    
   
   </>
  )
}

export default ModalFor1;
