import React from 'react';
import './modalfor5.css';

import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal5 } from '../../../redux/actions/modal';

const ModalFor5 = () => {

  const dispatch = useDispatch();
  const modalFor5 = useSelector((store) => store.modalFor5);

  // закрытие 
  const closeModal5 = () => {
    dispatch(setCloseModal5())
  }

  return (
  <>
    <div className='modalfor5'>
    <div className={ modalFor5 ? "modal active5" : "modal5"} onClick={closeModal5}>
      <div className={ modalFor5 ? "modal__content active5" : "modal__content5"} onClick={e => e.stopPropagation()}>
           <ul className='list5'>
             <li>Замена зеркал</li>
             <li>Внешний ремонт</li>
             <li>Замена фурнитуры</li>
             <li>Ремонт механизмов</li>
             <li>Монтаж перегородок</li>
             <li>Изменение облицовки</li>
             <li>Замена/ремонт полок</li>
             <li>Замена/ремонт ящиков</li>
             <li>Монтаж/демонтаж дверей</li>
             <li>Сборка/разборка/подгонка</li>
           </ul>
      </div>
      <img src="/svg/x-lg.svg" alt="закрыть окошко" title="Закрыть" style={{width: "25px", height: "25px", padding: "1px", cursor: "pointer"}} onClick={closeModal5}/>
    </div>
    </div>
    
   
   </>
  )
}

export default ModalFor5;
