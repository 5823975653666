import { combineReducers } from 'redux';
import { mainModalReducer1 } from './mainModalReducer';
import { galleryReducer } from './galleryReducer';
import { requestReducer } from './requestReducer';
import { requestInputsReducer } from './reqInpReducer';
import { giftsReducer } from './giftsReducer';
import { thankReducer } from './thanksReducer'; 
import { bioReducer } from './bioReducer';


import { modalReducer1 } from './modalReducer1';
import { modalReducer2 } from './modalReducer2';
import { modalReducer3 } from './modalReducer3';
import { modalReducer4 } from './modalReducer4';
import { modalReducer5 } from './modalReducer5';
import { modalReducer6 } from './modalReducer6';

import { wordsReducer } from './wordsReducer';


export const rootReducer = combineReducers({

  mainModal: mainModalReducer1,
  gallery: galleryReducer,
  request: requestReducer,
  requestInputs: requestInputsReducer,
  gifts: giftsReducer,
  thanks: thankReducer,
  bio: bioReducer,

  modalFor1: modalReducer1,
  modalFor2: modalReducer2,
  modalFor3: modalReducer3,
  modalFor4: modalReducer4,
  modalFor5: modalReducer5,
  modalFor6: modalReducer6,

  words: wordsReducer,

})
